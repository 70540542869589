import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Navigate } from "react-router-dom";
import { useAsync } from "react-use";
import { PageSpin } from "@worksolutions/antd-react-components";

import { appRoutes } from "shared/model/services";

import { viewerService } from "./service";

export const ViewerProvider = React.memo(function ViewerProvider({ children }: { children: React.ReactNode }) {
  const { loading } = useAsync(viewerService.loadViewer.bind(viewerService), []);

  if (loading) return <PageSpin />;

  return <ErrorBoundary fallback={<Navigate replace to={appRoutes.getAuthUrl()} />}>{children}</ErrorBoundary>;
});
