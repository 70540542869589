import { isNil } from "ramda";
import { Container, Inject, Service } from "typedi";

import { MediasTransportToken } from "shared/api";
import { getErrorMessage } from "shared/lib/getErrorMessage";

import type { MediasTransport } from "../interfaces";

@Service()
export class PreviewImageService {
  constructor(
    @Inject(MediasTransportToken)
    private readonly _mediasTransport: MediasTransport,
  ) {}

  public async previewImageFromUrl(url: string): Promise<void> {
    try {
      const blob = await this._mediasTransport.getMediaBlob({ urlParams: { url } });

      const src: string | ArrayBuffer | null = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = () => resolve(reader.result);
      });

      if (isNil(src) || src instanceof ArrayBuffer) {
        throw new Error("Could not read the file src");
      }

      const image = new Image();
      image.src = src;
      const imgWindow = window.open(src);
      imgWindow?.document.write(image.outerHTML);
    } catch (e) {
      console.error(getErrorMessage(e));
    }
  }
}

export const previewImageService = Container.get(PreviewImageService);
