import React, { useState } from "react";
import type { UploadFile } from "antd";
import { isNil } from "ramda";

interface UploaderContext {
  state: UploadFile[];
  setState: React.Dispatch<React.SetStateAction<UploadFile[]>>;
}

const Context = React.createContext<UploaderContext | null>(null);

interface UploaderProviderProps extends React.PropsWithChildren {
  initialState?: UploadFile[];
}

export const UploaderProvider = React.memo(function UploaderProvider({
  initialState = [],
  children,
}: UploaderProviderProps) {
  const [state, setState] = useState<UploadFile[]>(initialState);

  return <Context.Provider value={{ state, setState }}>{children}</Context.Provider>;
});

export function useUploaderContext(): UploaderContext {
  const context = React.useContext(Context);
  if (isNil(context)) throw new Error("UploaderContext not found");

  return context;
}
