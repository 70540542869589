import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { EyeFilled, ToolFilled } from "@ant-design/icons";
import { ConfigProvider, Switch, type ThemeConfig } from "antd";

import { UserRole } from "entities/@common/user";
import { useViewer } from "entities/viewer";

import { appRoutes } from "shared/model/services";

const theme: ThemeConfig = {
  components: {
    Switch: {
      colorTextQuaternary: "#4EACE8",
      colorTextTertiary: "#0B7AC2",
    },
  },
};

export const SwitchRoleFeature = observer(function SwitchRoleFeature() {
  const viewer = useViewer();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isAdminRoutes = pathname.startsWith(appRoutes.getAdminRootUrl());

  if (viewer.state.role === UserRole.Student) return null;

  return (
    <ConfigProvider theme={theme}>
      <Switch
        checkedChildren={<ToolFilled />}
        unCheckedChildren={<EyeFilled />}
        value={isAdminRoutes}
        onChange={() => {
          navigate(isAdminRoutes ? appRoutes.getStudentRootUrl() : appRoutes.getAdminRootUrl());
        }}
      />
    </ConfigProvider>
  );
});
