import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
  AppstoreAddOutlined,
  AppstoreOutlined,
  SolutionOutlined,
  TeamOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import { string1 } from "@worksolutions/utils";
import { Layout, Menu, type MenuProps, Row } from "antd";
import { memoizeWith } from "ramda";

import { appRoutes } from "shared/model/services";
import { LmsLogo } from "shared/ui/lmsLogo";

const menuItemRoutes = [
  {
    key: appRoutes.getAdminUsersUrl(true),
    label: <Link to={appRoutes.getAdminUsersUrl()}>Все пользователи</Link>,
    icon: <TeamOutlined />,
  },
  {
    key: appRoutes.getAdminCreateUserUrl(true),
    label: <Link to={appRoutes.getAdminCreateUserUrl()}>Добавить пользователя</Link>,
    icon: <UserAddOutlined />,
  },
  {
    key: appRoutes.getAdminCoursesUrl(true),
    label: <Link to={appRoutes.getAdminCoursesUrl()}>Все курсы</Link>,
    icon: <AppstoreOutlined />,
  },
  {
    key: appRoutes.getAdminCreateCourseUrl(true),
    label: <Link to={appRoutes.getAdminCreateCourseUrl()}>Добавить курс</Link>,
    icon: <AppstoreAddOutlined />,
  },
  {
    key: "#3",
    label: <Link to="#3">Список обучающихся</Link>,
    icon: <SolutionOutlined />,
  },
];

const menuItems: MenuProps["items"] = [
  {
    type: "group",
    label: "ПОЛЬЗОВАТЕЛИ",
    className: "mb-16",
    children: menuItemRoutes.slice(0, 2),
  },
  {
    type: "group",
    label: "КУРСЫ",
    className: "mb-16",
    children: menuItemRoutes.slice(2, 4),
  },
  {
    type: "group",
    label: "ПРОВЕРКА ЗАДАНИЙ",
    children: menuItemRoutes.slice(4),
  },
];

const findActiveKey: (pathname: string) => string | undefined = memoizeWith(string1, (pathname) => {
  let matchKey: string | undefined;

  for (const route of menuItemRoutes) {
    if (route.key === pathname) {
      matchKey = route.key;
      break;
    }

    if (pathname.startsWith(route.key)) {
      matchKey = route.key;
    }
  }

  return matchKey;
});

export const Sidebar = React.memo(function Sidebar() {
  const { pathname } = useLocation();
  const activeKey = findActiveKey(pathname);
  const selectedKeys = activeKey ? [activeKey] : [];

  return (
    <Layout.Sider width={300}>
      <Row className="mb-8 p-4">
        <LmsLogo linkPath={appRoutes.getAdminRootUrl()} />
      </Row>
      <Row>
        <Menu items={menuItems} mode="inline" selectedKeys={selectedKeys} />
      </Row>
    </Layout.Sider>
  );
});
