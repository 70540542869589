import { Service, Token } from "typedi";

import { METHODS, RequestManager } from "shared/lib/requestManager";

import type { ChangePasswordRequest, Viewer, ViewerTransport } from "../interfaces";

import { changePasswordDecoder, profileDecoder } from "./decoder";

export const ViewerTransportToken = new Token<ViewerTransport>("ViewerTransport");

@Service(ViewerTransportToken)
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class ViewerApi extends RequestManager implements ViewerTransport {
  public async getProfile(): Promise<Viewer> {
    return this.createRequest({
      url: "/api/users-permissions/getUserInfo",
      serverDataDecoder: profileDecoder,
    })();
  }

  public async changePassword(request: ChangePasswordRequest): Promise<string> {
    return this.createRequest({
      url: "/api/auth/change-password",
      method: METHODS.POST,
      serverDataDecoder: changePasswordDecoder,
    })(request);
  }
}
