import React from "react";
import { Navigate } from "react-router-dom";

import { UserRole } from "entities/@common/user";
import { useViewer } from "entities/viewer";

import { appRoutes } from "shared/model/services";

export const AdminPagesGuard = React.memo(function AdminPagesGuard({ children }: React.PropsWithChildren) {
  const viewer = useViewer();

  if (viewer.state.role === UserRole.Admin) return <>{children}</>;
  return <Navigate replace to={appRoutes.getStudentRootUrl()} />;
});
