import { is, map, omit } from "ramda";

export interface StrapiEncode<T> {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  __STRAPI_RESPONSE_NEEDS_TO_BE_DECODED__: T;
}

export type StrapiDecode<T> = T extends StrapiEncode<infer R> ? R : T;

const removeObjectKey = <T, K extends keyof T>(object: T, key: K): Omit<T, string> & T[K] => ({
  ...omit([key as string], object),
  ...object[key],
});

export function decodeStrapi<T>(strapi: T): StrapiDecode<T> {
  if (!is(Object, strapi)) return strapi;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  if (is(Array, strapi)) return map(decodeStrapi, strapi);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  if (strapi.data === null) return null;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-assignment
  if (strapi.data && is(Object, strapi.data) && !is(Array, strapi.data)) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
      return decodeStrapi(removeObjectKey(strapi, "data"));
    }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  if (strapi.attributes) return decodeStrapi(removeObjectKey(strapi, "attributes"));

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return map((property) => decodeStrapi(property), strapi);
}
