import Decoder, { field, number, string, succeed } from "jsonous";

import type { Media, MediaFormat } from "shared/model/interfaces";

import { decodeNumberToString, fieldOrFallback, ISODateDecoder } from "./common";

const mediaFormatDecoder: Decoder<MediaFormat> = succeed({})
  .assign("name", field("name", string))
  .assign("hash", field("hash", string))
  .assign("ext", field("ext", string))
  .assign("mime", field("mime", string))
  .assign("path", fieldOrFallback("path", string))
  .assign("width", field("width", number))
  .assign("height", field("height", number))
  .assign("size", field("size", number))
  .assign("url", field("url", string));

const mediaFormatsDecoder: Decoder<Media["formats"]> = succeed({})
  .assign("thumbnail", fieldOrFallback("thumbnail", mediaFormatDecoder, undefined))
  .assign("small", fieldOrFallback("small", mediaFormatDecoder, undefined))
  .assign("medium", fieldOrFallback("medium", mediaFormatDecoder, undefined))
  .assign("large", fieldOrFallback("large", mediaFormatDecoder, undefined));

export const mediaDecoder: Decoder<Media> = succeed({})
  .assign("id", field("id", decodeNumberToString))
  .assign("name", field("name", string))
  .assign("alternativeText", fieldOrFallback("alternativeText", string))
  .assign("caption", fieldOrFallback("caption", string))
  .assign("width", field("width", number))
  .assign("height", field("height", number))
  .assign("formats", field("formats", mediaFormatsDecoder))
  .assign("hash", field("hash", string))
  .assign("ext", field("ext", string))
  .assign("mime", field("mime", string))
  .assign("size", field("size", number))
  .assign("url", field("url", string))
  .assign("previewUrl", fieldOrFallback("previewUrl", string))
  .assign("provider", field("provider", string))
  .assign("createdAt", field("createdAt", ISODateDecoder))
  .assign("updatedAt", field("updatedAt", ISODateDecoder));
