import type Decoder from "jsonous";
import { field, string } from "jsonous";

import { userDecoder } from "entities/@common/user";

import type { Viewer } from "../interfaces";

export const profileDecoder: Decoder<Viewer> = userDecoder;

export const changePasswordDecoder = field("jwt", string);
