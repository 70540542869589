import { Container, Service } from "typedi";

@Service()
export class AppRoutes {
  public getAdminRootUrl = () => "/management";

  public getAdminProfileUrl = (withPrefix: boolean = false) =>
    `${this._calculatePrefix(this.getAdminRootUrl(), withPrefix)}profile`;

  public getAdminUsersUrl = (withPrefix: boolean = false) =>
    `${this._calculatePrefix(this.getAdminRootUrl(), withPrefix)}users`;

  public getAdminCreateUserUrl = (withPrefix: boolean = false) =>
    `${this._calculatePrefix(this.getAdminRootUrl(), withPrefix)}users/create`;

  public getAdminUpdateUserUrl = (withPrefix: boolean = false, id = ":id") =>
    `${this._calculatePrefix(this.getAdminRootUrl(), withPrefix)}users/${id}/update`;

  public getAdminCoursesUrl = (withPrefix: boolean = false) =>
    `${this._calculatePrefix(this.getAdminRootUrl(), withPrefix)}courses`;

  public getAdminCreateCourseUrl = (withPrefix: boolean = false) =>
    `${this._calculatePrefix(this.getAdminRootUrl(), withPrefix)}courses/create`;

  public getAdminUpdateCourseUrl = (withPrefix: boolean = false, id = ":id") =>
    `${this._calculatePrefix(this.getAdminRootUrl(), withPrefix)}courses/${id}/update`;

  public getStudentRootUrl = () => "/";

  public getStudentProfileUrl = (withPrefix: boolean = false) =>
    `${this._calculatePrefix(this.getStudentRootUrl(), withPrefix)}profile`;

  public getAuthUrl = () => "/auth";

  public getResetPasswordUrl = (withPrefix: boolean = false) =>
    `${this._calculatePrefix(this.getAuthUrl(), withPrefix)}reset-password`;

  public getResetPasswordSuccessUrl = (withPrefix: boolean = false) =>
    `${this._calculatePrefix(this.getAuthUrl(), withPrefix)}reset-password-success`;

  public getChangePasswordUrl = (withPrefix: boolean = false) =>
    `${this._calculatePrefix(this.getAuthUrl(), withPrefix)}change-password`;

  private readonly _calculatePrefix = (prefix: string, withPrefix: boolean) => (withPrefix ? `${prefix}/` : "");
}

export const appRoutes = Container.get(AppRoutes);
