import React from "react";
import { useMount } from "react-use";
import { useConfirmationModal } from "@worksolutions/antd-react-components";
import { DownloadOutlined } from "@ant-design/icons";
import { isNotNil } from "@worksolutions/utils";
import { Upload, type UploadFile, type UploadProps } from "antd";
import ImgCrop from "antd-img-crop";
import { isNil } from "ramda";

import { previewImageService } from "shared/model/services";

import { useUploaderContext } from "../config";

export interface SingleFileUploaderProps
  extends Omit<
    UploadProps,
    "children" | "defaultFileList" | "fileList" | "maxCount" | "beforeUpload" | "onRemove" | "onChange" | "onPreview"
  > {
  initialState?: UploadFile[];
  beforeUpload: (file: File) => Promise<void> | void;
  onRemove: () => Promise<void> | void;
}

export const UploadSingleFile = React.memo(function UploadSingleFile({
  initialState,
  beforeUpload,
  onRemove,
  ...props
}: SingleFileUploaderProps) {
  const { state, setState } = useUploaderContext();
  const [withConfirmation, ConfirmationDialog] = useConfirmationModal();

  const handlePreview = async (file: UploadFile) => {
    if (isNil(file.url)) return;
    await previewImageService.previewImageFromUrl(file.url);
  };

  const onRemoveCallback: () => Promise<void> = async () => {
    await onRemove();
    setState([]);
  };

  const handleRemove: UploadProps["onRemove"] = initialState ? withConfirmation(onRemoveCallback) : onRemoveCallback;

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    if (newFileList.length === 0) return;
    setState(newFileList);
  };

  const handlerBeforeUpload: UploadProps["beforeUpload"] = async (file, fileList) => {
    setState(fileList);
    await beforeUpload(file);
    return false;
  };

  useMount(() => initialState && setState(initialState));

  return (
    <>
      {isNotNil(initialState) && <ConfirmationDialog subtitle="Подтвердите ваше действие" title="Удалить файл?" />}
      <ImgCrop rotationSlider maxZoom={10}>
        <Upload
          accept="image/png, image/jpeg"
          beforeUpload={handlerBeforeUpload}
          defaultFileList={[]}
          fileList={state}
          listType="picture-circle"
          maxCount={1}
          onChange={handleChange}
          onPreview={handlePreview}
          onRemove={handleRemove}
          {...props}
        >
          {state.length === 0 && <DownloadOutlined size={40} />}
        </Upload>
      </ImgCrop>
    </>
  );
});
