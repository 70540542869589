import { isNil } from "ramda";

import type { Media } from "shared/model/interfaces";
import type { MediasService } from "shared/model/services";

export class ManageEntityImageService {
  protected _tempImage: Media | null = null;

  constructor(protected readonly _mediasService: MediasService) {}

  public async removeImage(avatar?: Media): Promise<void> {
    if (isNil(avatar)) return;
    await this._mediasService.deleteMedia(avatar.id);
    this._tempImage = null;
  }

  public async removeTempImage(): Promise<void> {
    if (isNil(this._tempImage)) return;
    await this._mediasService.deleteMedia(this._tempImage.id);
    this._tempImage = null;
  }

  public async uploadImage(file: File): Promise<Media> {
    const media = await this._mediasService.createSingleMedia(file);
    this._tempImage = media;
    return media;
  }
}
