import { Container, Inject, Service } from "typedi";

import {
  type AuthTransport,
  AuthTransportToken,
  type ChangePasswordDto,
  type LoginDto,
  type ResetPasswordDto,
} from "entities/auth";
import { ViewerService } from "entities/viewer";

import { CookiesStore, cookiesStore } from "shared/model/services";

@Service()
export class AuthService {
  @Inject(AuthTransportToken)
  private readonly _transport!: AuthTransport;

  @Inject()
  private readonly _viewerService!: ViewerService;

  @Inject()
  private readonly _cookiesStore!: CookiesStore;

  public async login(body: LoginDto): Promise<void> {
    const token = await this._transport.loginRequest({ body });
    this._cookiesStore.set("token", `Bearer ${token}`, { secure: true, expires: 365 });
  }

  public async changePassword(body: ChangePasswordDto): Promise<void> {
    await this._transport.changePasswordRequest({ body });
  }

  public async checkResetCode(code: string): Promise<boolean> {
    return this._transport.checkResetCodeRequest({ additionalQueryParams: { code } });
  }

  public async resetPassword(body: ResetPasswordDto): Promise<void> {
    await this._transport.resetPasswordRequest({ body });
  }

  public logout(): void {
    cookiesStore.remove("token");
    this._viewerService.logout();
  }
}

export const authService = Container.get(AuthService);
