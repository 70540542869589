import { Container, Service } from "typedi";

@Service()
export class AppTitles {
  public getRootTitle = () => "WS LMS";

  public getUsersTitle = () => "WS LMS: Пользователи";
  public getAuthTitle = () => "WS LMS: Авторизация";
  public getProfileTitle = () => "WS LMS: Профиль";
  public getResetPasswordTitle = () => "WS LMS: Восстановление пароля";
  public getResetPasswordSuccessTitle = () => "WS LMS: Восстановление пароля";
  public getChangePasswordTitle = () => "WS LMS: Новый пароль";
  public getCoursesTitle = () => "WS LMS: Курсы";
  public getCreateCourseTitle = () => "WS LMS: Создание курса";
  public getUpdateCourseTitle = () => "WS LMS: Редактирование курса";
}

export const appTitles = Container.get(AppTitles);
