import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useConfirmationModal } from "@worksolutions/antd-react-components";
import { Typography } from "antd";

import { appRoutes } from "shared/model/services";

import { authService } from "../service";

export const LogoutFeature = React.memo(function LogoutButton() {
  const navigate = useNavigate();
  const [withConfirmation, ConfirmationDialog] = useConfirmationModal();

  const logout = useCallback(() => {
    authService.logout();
    navigate(appRoutes.getAuthUrl());
  }, [authService]);

  return (
    <>
      <ConfirmationDialog cancelText="Отменить" okText="Выйти" subtitle="Вы уверены, что хотите выйти?" title="Выйти" />
      <Typography.Text type="danger" onClick={withConfirmation(logout)}>
        Выйти
      </Typography.Text>
    </>
  );
});
