import type { UploadFile } from "antd";
import { memoizeWith } from "ramda";

import type { Media } from "shared/model/interfaces";

import { MediasService } from "../medias";

export class UploadService {
  public static convertMediaToUploadFile: (media: Media) => UploadFile = memoizeWith(JSON.stringify, (media) => ({
    uid: media.id,
    size: media.size,
    name: media.name,
    status: "done",
    url: MediasService.createMediaFullPath(media.url),
    thumbUrl: media.formats.thumbnail ? MediasService.createMediaFullPath(media.formats.thumbnail.url) : "",
  }));
}
