/* eslint-disable import/no-internal-modules */

import { Outlet } from "react-router";
import { createBrowserRouter } from "react-router-dom";

import Header, { HeaderProvider } from "widgets/header";
import { Sidebar } from "widgets/sidebar";

import { AdminPagesGuard } from "features/guards/adminPagesGuard";

import { ViewerProvider } from "entities/viewer";

import { appRoutes } from "shared/model/services";
import { Layout } from "shared/ui/layout";

// TODO: fix second render
export const browserRouter = createBrowserRouter([
  {
    path: "*",
    element: "error",
  },
  {
    path: appRoutes.getStudentRootUrl(),
    element: (
      <ViewerProvider>
        <HeaderProvider>
          <Layout.Main content={<Outlet />} header={<Header.Student />} />
        </HeaderProvider>
      </ViewerProvider>
    ),
    children: [
      { index: true, lazy: async () => import("pages/student/home") },
      { path: appRoutes.getStudentProfileUrl(), lazy: async () => import("pages/common/profile") },
    ],
  },
  {
    path: appRoutes.getAdminRootUrl(),
    element: (
      <ViewerProvider>
        <HeaderProvider>
          <AdminPagesGuard>
            <Layout.Main content={<Outlet />} header={<Header.Admin />} sidebar={<Sidebar />} />
          </AdminPagesGuard>
        </HeaderProvider>
      </ViewerProvider>
    ),
    children: [
      { index: true, lazy: async () => import("pages/admin/home") },

      { path: appRoutes.getAdminProfileUrl(), lazy: async () => import("pages/common/profile") },

      { path: appRoutes.getAdminUsersUrl(), lazy: async () => import("pages/admin/users/root") },
      { path: appRoutes.getAdminCreateUserUrl(), lazy: async () => import("pages/admin/users/create") },
      { path: appRoutes.getAdminUpdateUserUrl(), lazy: async () => import("pages/admin/users/update") },

      { path: appRoutes.getAdminCoursesUrl(), lazy: async () => import("pages/admin/courses/root") },
      { path: appRoutes.getAdminCreateCourseUrl(), lazy: async () => import("pages/admin/courses/create") },
      { path: appRoutes.getAdminUpdateCourseUrl(), lazy: async () => import("pages/admin/courses/update") },
    ],
  },
  {
    path: appRoutes.getAuthUrl(),
    element: <Layout.Auth content={<Outlet />} />,
    children: [
      { index: true, lazy: async () => import("pages/common/auth/login") },
      { path: appRoutes.getResetPasswordUrl(), lazy: async () => import("pages/common/auth/resetPassword") },
      { path: appRoutes.getChangePasswordUrl(), lazy: async () => import("pages/common/auth/changePassword") },
      {
        path: appRoutes.getResetPasswordSuccessUrl(),
        lazy: async () => import("pages/common/auth/resetPasswordSuccess"),
      },
    ],
  },
]);
