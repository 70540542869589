import { UploaderProvider } from "./config";
import { type SingleFileUploaderProps, UploadSingleFile } from "./single";

export const Uploader = {
  Single: UploadSingleFile,
  Provider: UploaderProvider,
};

export { useUploaderContext } from "./config";

export interface UploaderProps {
  Single: SingleFileUploaderProps;
}
