import { array } from "jsonous";
import { Service, Token } from "typedi";

import { METHODS, RequestManager } from "shared/lib/requestManager";
import type {
  CreateMediaRequest,
  DeleteMediaRequest,
  GetMediaBlobRequest,
  GetMediaRequest,
  Media,
  MediasTransport,
} from "shared/model/interfaces";

import { mediaDecoder } from "../decoders";

export const MediasTransportToken = new Token<MediasTransport>("MediasTransport");

@Service(MediasTransportToken)
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class MediasApi extends RequestManager implements MediasTransport {
  public async getMedia({ urlParams }: GetMediaRequest): Promise<Media> {
    return this.createRequest({
      url: "/api/upload/files/{id}",
      serverDataDecoder: mediaDecoder,
    })({ urlParams });
  }

  public async getMediaBlob({ urlParams }: GetMediaBlobRequest): Promise<Blob> {
    // TODO send request with requestManager
    const response = await fetch(urlParams.url);
    return response.blob();
  }

  // TODO add folder ??? fileInfo: {"name":"1 screen.jpeg","folder":1}
  public async createMedia({ body }: CreateMediaRequest): Promise<Media[]> {
    const formData = new FormData();
    body.forEach((file) => formData.append("files", file));

    return this.createRequest({
      url: "/api/upload",
      method: METHODS.POST,
      serverDataDecoder: array(mediaDecoder),
      requestConfig: { contentType: "multipart/form-data" },
    })({ body: formData });
  }

  public async deleteMedia({ urlParams }: DeleteMediaRequest): Promise<void> {
    await this.createRequest({
      url: "/api/upload/files/{id}",
      method: METHODS.DELETE,
    })({ urlParams });
  }
}
