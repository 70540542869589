import { string1 } from "@worksolutions/utils";
import { memoizeWith } from "ramda";
import { Container, Inject, Service } from "typedi";

import { MediasTransportToken } from "shared/api";
import { BASE_API_HOST } from "shared/config/const";

import type { Media, MediasTransport, UniqueEntity } from "../interfaces";

@Service()
export class MediasService {
  public static createMediaFullPath: (url: string) => string = memoizeWith(string1, (url) => BASE_API_HOST + url);

  @Inject(MediasTransportToken)
  private readonly _mediasTransport!: MediasTransport;

  public static getEntityMediaFullPath = <
    T extends UniqueEntity,
    K extends keyof T &
      {
        [P in keyof T]: T[P] extends Media | null ? P : never;
      }[keyof T],
  >(
    entity: T,
    key: K,
  ): string | undefined => {
    if (!entity[key]) return;
    const media: Media = entity[key] as Media;
    return MediasService.createMediaFullPath(media.url);
  };

  public async deleteMedia(id: Guid): Promise<void> {
    await this._mediasTransport.deleteMedia({ urlParams: { id } });
  }

  public async createSingleMedia(file: File): Promise<Media> {
    const [media] = await this._mediasTransport.createMedia({ body: [file] });
    return media;
  }

  public async createMediaList(filesList: File[]): Promise<Media[]> {
    return this._mediasTransport.createMedia({ body: filesList });
  }
}

export const mediasService = Container.get(MediasService);
