import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Dropdown, type MenuProps, Typography } from "antd";

import { LogoutFeature } from "features/auth/logout";
import { SwitchRoleFeature } from "features/profile/switchRole";

import { UserView } from "entities/@common/user";
import { useViewer } from "entities/viewer";

import { appRoutes } from "shared/model/services";
import { LmsLogo } from "shared/ui/lmsLogo";

import { useHeaderContext } from "./config";

const UserWidget = observer(function UserWidget() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const viewer = useViewer();

  const userWidgetItems: MenuProps["items"] = React.useMemo(
    () => [
      {
        label: (
          <Typography.Text
            onClick={() =>
              navigate(
                pathname.startsWith(appRoutes.getAdminRootUrl())
                  ? appRoutes.getAdminProfileUrl()
                  : appRoutes.getStudentProfileUrl(),
              )
            }
          >
            Профиль
          </Typography.Text>
        ),
        key: "0",
      },
      {
        type: "divider",
      },
      {
        label: <LogoutFeature />,
        key: "2",
      },
    ],
    [pathname],
  );

  return (
    <Dropdown menu={{ items: userWidgetItems }} trigger={["click"]}>
      <UserView.Avatar className="cursor-pointer ml-2" user={viewer.state} />
    </Dropdown>
  );
});

function AdminHeader() {
  const { node } = useHeaderContext();

  return (
    <div className="w-full flex justify-end p-4">
      {node && <div className="flex-1 flex items-center">{node}</div>}
      <div className="flex items-center">
        <SwitchRoleFeature />
        <UserWidget />
      </div>
    </div>
  );
}

function StudentHeader() {
  const { node } = useHeaderContext();

  return (
    <div className="w-full flex justify-between p-4">
      <LmsLogo linkPath={appRoutes.getStudentRootUrl()} />
      {node}
      <div className="flex items-center">
        <SwitchRoleFeature />
        <UserWidget />
      </div>
    </div>
  );
}

export { useHeader, HeaderProvider } from "./config";
export default {
  Admin: React.memo(AdminHeader),
  Student: React.memo(StudentHeader),
};
